import React from "react"
import useMediaQuery from "../hooks/useMediaQuery"

interface CircleProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode // Content to display inside the circle
  size?: number // Size of the circle in pixels
  className?: string // Additional class names
  mobileSize?: number
}

const Circle: React.FC<CircleProps> = ({
  children,
  mobileSize,
  size = 50, // Default size
  className = "", // Default to an empty string
  style,
  ...props
}) => {
  const isMobile = useMediaQuery("md", "down")
  const circleStyles: React.CSSProperties = {
    width: isMobile ? `${mobileSize}px` : `${size}px`,
    height: isMobile ? `${mobileSize}px` : `${size}px`,
    borderRadius: "50%", // Makes the div a circle
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...style, // Allow custom styles
  }

  return (
    <div style={circleStyles} className={className} {...props}>
      {children}
    </div>
  )
}

export default Circle
