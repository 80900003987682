import { Button, Stack } from 'src/UI';

import useDemoResultsStore from '../store/useDemoResultsStore';
import useDemoStepStore from '../store/useDemoStepStore';
import DemoResultsTables from './DemoResultsTables';
import solarData from '../data/solarData';

const DemoConsumptionResultsStep = () => {
  const savingsData = useDemoResultsStore((s) => s.savingsData)
  const handleNext = useDemoStepStore((s) => s.handleNext)

  return (
    <>
      <Stack direction="column" gap={2} className="w-full">
        {savingsData && <DemoResultsTables response={savingsData} />}
        <Stack direction={"row"} gap={1} className={`w-full justify-center`}>
          <Button
            style={{
              maxWidth: 200,
              width: "100%",
            }}
            variant="primary"
            className="rounded-xl border-black border"
            onClick={handleNext}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default DemoConsumptionResultsStep
