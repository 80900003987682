import React, { forwardRef } from "react";
import Button from "./Button"; // Assuming you have a Button component
import Paragraph from "../Typography/Paragraph";

interface InputFieldProps {
  label: string;
  type: React.HTMLInputTypeAttribute;
  id: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  className?: string; // Custom className for the input field
  // Remove value and onChange, as these are handled by react-hook-form
}

// Use React.forwardRef to pass ref to the underlying input
const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      type,
      id,
      placeholder,
      required,
      error,
      buttonText,
      onButtonClick,
      className,
      ...rest // Spread props for react-hook-form's register
    },
    ref
  ) => {
    const hasButton = buttonText && onButtonClick;

    return (
      <div className="w-full flex flex-col gap-2">
        <label
          htmlFor={id}
          className={`block text-sm text-gray-600 ${required ? `label-required` : ``}`}
        >
          {label}
        </label>

        <div
          className={`flex ${
            hasButton ? "border rounded-md overflow-hidden" : "border rounded"
          }`}
        >
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            ref={ref} // Attach forwarded ref here
            {...rest} // Spread register's props here
            className={`w-full rounded-md px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500 ${className}`}
          />

          {hasButton && (
            <Button
              variant="secondary"
              className="px-10 bg-blue-500 text-white rounded-l-none"
              onClick={onButtonClick}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              {buttonText}
            </Button>
          )}
        </div>

        {error && <Paragraph className="text-red-500 text-sm">{error}</Paragraph>}
      </div>
    );
  }
);

// Add display name for debugging purposes
InputField.displayName = "InputField";

export default InputField;
