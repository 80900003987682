import { useState } from "react"
import { useForm } from "react-hook-form"
import { Button, InputField, OTPInputFields, Stack } from "src/UI"

import useDemoStepStore from "../store/useDemoStepStore"
import usePersonalInfoMutation from "../api/usePersonalInfoMutation"
import toast from "react-hot-toast"
import personalInfoSchema from "../schemas/personalInfoSchema"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const DemoInfoInputStep = () => {
  const handleNext = useDemoStepStore((s) => s.handleNext)
  const handlePrevious = useDemoStepStore((s) => s.handlePrevious)
  const [isOTPVisible, setIsOTPVisible] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { mutateAsync, isLoading } = usePersonalInfoMutation()

  type PersonalFormData = z.infer<typeof personalInfoSchema>

  const { register, handleSubmit, formState: { errors } } = useForm<PersonalFormData>({
    resolver: zodResolver(personalInfoSchema),
    defaultValues: {
      phone: "",
      name: "",
      email: "",
    },
  })

  return (
    <>
      {isSubmitted && (
        <div style={{ height: "40vh" }} className="flex items-center">
          <h1>
            Thank you for providing your information. We will be in touch with
            you shortly!
          </h1>
        </div>
      )}

      {!isSubmitted && (
        <>
          <form className="rounded w-full flex flex-col gap-5">
            <InputField
              label="Phone"
              required
              {...register("phone")}
              type="text"
              id="phone"
              placeholder="Enter your Phone number"
              buttonText="Verify"
              error={errors.phone?.message}
              // onButtonClick={() => {
              //   setIsOTPVisible(!isOTPVisible)
              // }}
            />

            {/* {isOTPVisible && <OTPInputFields />} */}

            <InputField
              label="Name"
              type="text"
              {...register("name")}
              id="name"
              placeholder="Enter your name"
              error={errors.name?.message}
            />

            <InputField
              label="Email"
              type="email"
              {...register("email")}
              id="email"
              placeholder="Enter your email"
              error={errors.email?.message}
            />
          </form>

          <Stack direction={"row"} gap={1} className={`w-full justify-between`}>
            <Button
              style={{
                maxWidth: 200,
                width: "100%",
              }}
              variant="primary"
              className="rounded-xl border-black border"
              onClick={handlePrevious}
            >
              Back
            </Button>

            <Button
              style={{
                maxWidth: 200,
                width: "100%",
              }}
              variant="secondary"
              className="rounded-xl border-black border"
              onClick={handleSubmit((data) => {
                mutateAsync(data)
                  .then((res) => {
                    toast.success("Result is received successfully")
                  })
                  .catch((err) => {
                    toast.error(
                      "An error occurred while submitting your data. Please try again later."
                    )
                  })
                console.log(data)
                setIsSubmitted(true)
              })}
            >
              Submit
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}

export default DemoInfoInputStep
