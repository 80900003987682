import { ReactNode } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

interface ParagraphProps {
  children: ReactNode;
  className?: string;
  mobileSize?: 'text-sm' | 'text-base';
}

const Paragraph = ({ children, className = '', mobileSize = 'text-sm' }: ParagraphProps) => {
  const isMobile = useMediaQuery('md', 'down');
  const baseClasses = `text-gray-600 ${isMobile ? mobileSize : 'text-base'}`;

  return (
    <p className={`${baseClasses} ${className}`}>
      {children}
    </p>
  );
};

export default Paragraph;
