import React, { useEffect } from "react"

const InteractiveDemoLink = () => {

  useEffect(() => {
    window.location.href = "https://optimalpv.netlify.app/"
  }, [])

  return <div>InteractiveDemoLink</div>
}

export default InteractiveDemoLink
