import { create } from "zustand";
import DemoInfoInputStep from "../components/DemoInfoInputStep";
import DemoLocationInputStep from "../components/DemoLocationInputStep";
import DemoConsumptionInputStep from "../components/DemoConsumptionInputStep";
import { ComponentType } from "react";
import DemoConsumptionResultsStep from "../components/DemoConsumptionResultsStep";

type InputStep = "Location" | "Consumption Average" | "Info";

const generateTitle = (isResult: boolean | undefined, step: InputStep) => {
  switch (step) {
    case "Consumption Average":
      return isResult
        ? "Consumption Results Data"
        : "Enter Your Consumption Details";
    case "Location":
      return "Select Your Location";
    case "Info":
      return "Enter Your Contact Information";
    default:
      return "";
  }
};

const stepComponents = (result: boolean) => ({
  Location: {
    component: DemoLocationInputStep,
    title: generateTitle(result, "Location"),
  },
  "Consumption Average": {
    component: result ? DemoConsumptionResultsStep : DemoConsumptionInputStep,
    title: generateTitle(result, "Consumption Average"),
  },
  Info: {
    component: DemoInfoInputStep,
    title: generateTitle(result, "Info"),
  },
});

interface DemoStepStore {
  steps: InputStep[];
  currentStep: InputStep;
  currentStepIndex: number;
  currentStepComponent: { component: ComponentType; title: string };
  hasResult: boolean;
  handleNext: (hasResult?: boolean) => void;
  handlePrevious: () => void;
  setHasResult: (value: boolean) => void;
  clear: () => void; // Add clear function to reset the store
}

const useDemoStepStore = create<DemoStepStore>((set) => ({
  steps: ["Location", "Consumption Average", "Info"],
  currentStep: "Location",
  currentStepIndex: 0,
  currentStepComponent: {
    component: DemoLocationInputStep,
    title: "Select Your Location",
  },
  hasResult: false,

  handleNext: () => {
    set((state) => {
      const { hasResult } = state;
      if (state.currentStep === "Consumption Average" && !hasResult) {
        let newResult = true;
        return {
          ...state,
          currentStep: "Consumption Average",
          currentStepIndex: state.currentStepIndex,
          currentStepComponent:
            stepComponents(newResult)["Consumption Average"],
          hasResult: newResult,
        };
      } else {
        const nextIndex = state.currentStepIndex + 1;
        if (nextIndex < state.steps.length) {
          const nextStep = state.steps[nextIndex];
          const nextStepComponent = stepComponents(hasResult)[nextStep];
          return {
            currentStep: nextStep,
            currentStepIndex: nextIndex,
            currentStepComponent: nextStepComponent,
            hasResult: hasResult, // Use correct property name here
          };
        }
      }

      return state;
    });
  },

  handlePrevious: () => {
    set((state) => {
      const prevIndex = state.currentStepIndex - 1;
      if (prevIndex >= 0) {
        const prevStep = state.steps[prevIndex];
        const prevStepComponent = stepComponents(state.hasResult)[prevStep];
        return {
          currentStep: prevStep,
          currentStepIndex: prevIndex,
          currentStepComponent: prevStepComponent,
        };
      }
      return state; // Stay on the current step if it's already the first one
    });
  },

  setHasResult: (value: boolean) => set({ hasResult: value }),

  // Clear function to reset the store to its initial state
  clear: () =>
    set({
      currentStep: "Location",
      currentStepIndex: 0,
      currentStepComponent: {
        component: DemoLocationInputStep,
        title: "Select Your Location",
      },
      hasResult: false,
    }),
}));

export default useDemoStepStore;
