import { ConsumptionTypes } from "../types/ConsumptionFormType"

export const consumptionFormChoices: {
  type: ConsumptionTypes
  label: string
  disabled?: boolean
}[] = [
  { type: "average", label: "Enter consumption average" },
  {
    type: "monthly",
    label: "Enter consumption average of the last 12 months",
  },
  { type: "pdf", label: "Upload your bill (PDF)", disabled: true },
]
