class AppRoutes {
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // Paths
  getLandingPagePath(): string {
    return `/`;
  }
  getLandingPageUrl(): string {
    return this.getLandingPagePath();
  }

  getDemoPagePath(): string {
    return `/request`;
  }
  getDemoPageUrl(): string {
    return this.getDemoPagePath();
  }

  getContractorPagePath(): string {
    return `/contractor`;
  }
  getContractorPageUrl(): string {
    return this.getContractorPagePath();
  }
  getAboutAsPageUrl(): string {
    return this.getAboutAsPagePath();
  }
  getAboutAsPagePath(): string {
    return `/about-as`;
  }
  getPrivacyPolicyPageUrl(): string {
    return this.getPrivacyPolicyPath();
  }
  getPrivacyPolicyPath(): string {
    return `/privacy-policy`;
  }
  getDemoModal(): string {
    return `/demo-modal`;
  }
  getInteractiveDemo(): string {
    return `/demo`;
  }
}

export const appRoutesObj = AppRoutes.getInstance();
