import { GoogleMap, useLoadScript } from "@react-google-maps/api"
import { useEffect, useState } from "react"
import { ImLocation } from "react-icons/im"
import LoadingComponent from "src/components/common/loading-component"
import { Box, Button, Stack } from "src/UI"

import useDemoSavingsStore from "../store/useDemoSavingsStore"
import useDemoStepStore from "../store/useDemoStepStore"
import { GoogleMapData } from "../types/LocationFormType"

const LocationMapForm = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  })
  const [map, setMap] = useState<google.maps.Map>({} as google.maps.Map)

  const defaultZoomLevel = 12
  const zoomLevel = useDemoSavingsStore((s) => s.savingsQuery.zoomLevel)
  const setZoomLevel = useDemoSavingsStore((s) => s.setZoomLevel)

  const location = useDemoSavingsStore((s) => s.savingsQuery.location)
  const setLocation = useDemoSavingsStore((s) => s.setLocation)
  let defaultLocation = { lat: 24.7136, lng: 46.5753 }

  const defaultMapType = "roadmap"
  const mapType = useDemoSavingsStore((s) => s.savingsQuery.mapType)
  const setMapType = useDemoSavingsStore((s) => s.setMapType)

  const handleNext = useDemoStepStore((s) => s.handleNext)

  useEffect(() => {
    if (!zoomLevel) {
      setZoomLevel(defaultZoomLevel)
    }

    if (!mapType) {
      setMapType(defaultMapType)
    }
  }, [])

  useEffect(() => {
    if (location) {
      setLocation(location) // Ensure store value is set
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const newLocation: GoogleMapData = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        setLocation(newLocation)
        defaultLocation = newLocation
      })
    } else {
      setLocation(defaultLocation)
    }
  }, [location, setLocation])

  const handleLocationChangeOnMap = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newCenter: GoogleMapData = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      setLocation(newCenter) // Update location in the store
    }
  }

  if (loadError) {
    return (
      <div className="h-[40rem] flex flex-col justify-center items-center">
        Map cannot be loaded right now, sorry
      </div>
    )
  }

  if (!isLoaded) {
    return (
      <div className="h-[40rem] flex flex-col justify-center items-center">
        <LoadingComponent />
      </div>
    )
  }

  return (
    <>
      <Box
        style={{
          border: "2px solid #D1D5DB",
          width: "100%",
          overflow: "hidden",
        }}
        className="border-r-4 h-96"
      >
        <GoogleMap
          zoom={zoomLevel}
          center={location}
          onLoad={(map) => setMap(map)}
          onClick={(e) => handleLocationChangeOnMap(e)}
          mapTypeId={mapType}
          mapContainerClassName="h-full w-full relative"
        >
          <div className="absolute top-[50%] left-[50%] z-10 ml-[-10px] mt-[-34px]">
            <ImLocation className="stroke-2 w-8 h-10 fill-yellow-txt text-yellow-txt" />
          </div>
        </GoogleMap>
      </Box>
      <Stack direction={"row"} gap={1} className={`w-full justify-center`}>
        <Stack
          direction="column"
          gap={1}
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            key="next"
            style={{
              maxWidth: 200,
              width: "100%",
            }}
            variant="primary"
            className="rounded-xl border-black border"
            onClick={() => {
              const newZoomValue = map.getZoom()
              setZoomLevel(newZoomValue!)

              const newMapType = map.getMapTypeId()
              setMapType(newMapType!)

              const { lat, lng } = map.getCenter()!
              setLocation({ lat: lat(), lng: lng() })
              handleNext()
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default LocationMapForm
