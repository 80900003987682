import { useMutation, useQueryClient } from "@tanstack/react-query"
import savingService from "src/services/savingService"

const useSavingsMutation = () => {
  const queryClient = useQueryClient() // Access queryClient for cache invalidation
  const CACHE_KEY_SAVINGS = "savings"

  return useMutation({
    mutationFn: savingService.post,
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries([CACHE_KEY_SAVINGS])
    },
    onError: (error) => {},
  })
}

export default useSavingsMutation
