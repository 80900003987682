import { z } from "zod"

const consumptionInfoSchema = z.discriminatedUnion("consumptionType", [
  z.object({
    consumptionType: z.literal("average"),
    lat: z.number(),
    long: z.number(),
    loadType: z.enum(["Commercial", "Residential", "Agricultural"]),
    averageConsumption: z
      .number()
      .min(1, "Average consumption must be greater than 0"),
    monthlyConsumption: z.optional(z.array(z.number())), // Ignored if `consumptionType` is "average"
  }),
  z.object({
    consumptionType: z.literal("monthly"),
    lat: z.number(),
    long: z.number(),
    loadType: z.enum(["Commercial", "Residential", "Agricultural"]),
    monthlyConsumption: z
      .array(z.number().min(1, "Monthly consumption must be greater than 0"))
      .length(12),
    averageConsumption: z.optional(z.number()), // Ignored if `consumptionType` is "monthly"
  }),
])

export default consumptionInfoSchema
