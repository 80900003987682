import { useMutation, useQueryClient } from "@tanstack/react-query"
import personalInfoService from "src/services/personalInfoService"

const usePersonalInfoMutation = () => {

  return useMutation({
    mutationFn: personalInfoService.post,
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {},
  })
}

export default usePersonalInfoMutation
