import { ReactNode } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

interface HeadingProps {
  children: ReactNode;
  level?: 1 | 2 | 3 | 4 | 5 | 6; // Heading level (h1, h2, etc.)
  className?: string; // Additional Tailwind or custom classes
  mobileSize?: 'text-4xl' | 'text-3xl' | 'text-2xl' | 'text-xl' | 'text-lg' | 'text-base';
}

const Heading = ({
  children,
  level = 1,
  className = '',
  mobileSize = 'text-2xl', 
}: HeadingProps) => {
  const isMobile = useMediaQuery('md', 'down');
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;
  const defaultClasses = 'font-bold text-gray-800';

  const levelClasses = {
    1: isMobile ? mobileSize : 'text-4xl',
    2: isMobile ? mobileSize : 'text-3xl',
    3: isMobile ? mobileSize : 'text-2xl',
    4: isMobile ? mobileSize : 'text-xl',
    5: isMobile ? mobileSize : 'text-lg',
    6: isMobile ? mobileSize : 'text-base',
  };

  return (
    <Tag className={`${defaultClasses} ${levelClasses[level]} ${className}`}>
      {children}
    </Tag>
  );
};

export default Heading;
