import { create } from "zustand";
import { SavingsDto } from "src/types/SavingsDto";

interface DemoResultsStore {
  savingsData: SavingsDto;
  setSavingsData: (data: SavingsDto) => void;
  clear: () => void; // Add a clear function
}

const useDemoResultsStore = create<DemoResultsStore>((set) => ({
  savingsData: {} as SavingsDto,
  setSavingsData: (data) => set(() => ({ savingsData: data })),
  clear: () => set(() => ({ savingsData: {} as SavingsDto })), // Reset to initial state
}));

export default useDemoResultsStore;
