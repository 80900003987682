import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import LoadingComponent from "src/components/common/loading-component"
import { Button, Stack } from "src/UI"
import { z } from "zod"

import useSavingsMutation from "../api/useSavingsMutation"
import { consumptionFormChoices } from "../data/consumptionFormChoices"
import consumptionInfoSchema from "../schemas/consumptionInfoSchema"
import useDemoResultsStore from "../store/useDemoResultsStore"
import useDemoSavingsStore from "../store/useDemoSavingsStore"
import useDemoStepStore from "../store/useDemoStepStore"
import { LoadType } from "../types/ConsumptionFormType"
import ComingSoonRibbon from "./ComingSoonRibbon"
import usePreventAccidentEnter from "../hooks/usePreventAccidentEnter"

const DemoConsumptionInputStep = () => {
  usePreventAccidentEnter()
  const location = useDemoSavingsStore((s) => s.savingsQuery.location)

  //DemoStep hooks
  const handlePrevious = useDemoStepStore((s) => s.handlePrevious)
  const handleNext = useDemoStepStore((s) => s.handleNext)

  //DemoResults hooks
  const setSavingsData = useDemoResultsStore((s) => s.setSavingsData)

  const { mutateAsync, isLoading } = useSavingsMutation()
  type ConsumptionFormData = z.infer<typeof consumptionInfoSchema>

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ConsumptionFormData>({
    resolver: zodResolver(consumptionInfoSchema),
    defaultValues: {
      lat: 0,
      long: 0,
      loadType: "Commercial",
      consumptionType: "average",
      averageConsumption: 0,
      monthlyConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  })

  const loadTypeValue = watch("loadType")
  const consumptionTypeValue = watch("consumptionType")
  const monthlyConsumption = watch("monthlyConsumption")

  const loadTypeChoices: { id: string; label: LoadType }[] = [
    { id: "commercial", label: "Commercial" },
    { id: "Residential", label: "Residential" },
    { id: "Agricultural", label: "Agricultural" },
  ]

  const onSubmit = (data: ConsumptionFormData) => {
    let submissionData: any = {
      lat: location.lat,
      long: location.lng,
      loadType: data.loadType,
      consumptionType: data.consumptionType,
    }

    if (data.consumptionType === "average") {
      submissionData = {
        ...submissionData,
        averageConsumption: data.averageConsumption,
      }
    } else if (data.consumptionType === "monthly") {
      submissionData = {
        ...submissionData,
        monthlyConsumption: data.monthlyConsumption,
      }
    }

    mutateAsync(submissionData)
      .then((res) => {
        toast.success("Result is received successfully")
        if (res) {
          setSavingsData(res)
          handleNext()
        }
      })
      .catch((err) => {
        toast.error(
          "An error occurred while submitting your data. Please try again later."
        )
      })
  }

  if (isLoading) {
    return (
      <div style={{ height: "40vh" }} className="flex items-center">
        <LoadingComponent />
      </div>
    )
  }

  return (
    <form>
      <Stack direction="column" gap={2} className="w-full">
        <div className="flex gap-10 w-full justify-center">
          {loadTypeChoices.map((loadTypeChoice) => (
            <div key={loadTypeChoice.id} className="flex flex-row gap-3">
              <label htmlFor={loadTypeChoice.id}>{loadTypeChoice.label}</label>
              <input
                type="radio"
                id={loadTypeChoice.id}
                value={loadTypeChoice.label}
                {...register("loadType")}
                checked={loadTypeValue === loadTypeChoice.label}
              />
            </div>
          ))}
        </div>

        <div className="grid grid-cols-12 space-x-3 w-full h-30">
          {consumptionFormChoices.map((formChoice) => (
            <div
              key={formChoice.type}
              className="col-span-4 rounded-md relative"
            >
              {formChoice.disabled && <ComingSoonRibbon />}
              <label
                htmlFor={formChoice.type}
                className={`border p-4 rounded-md text-center h-full flex items-center justify-center ${
                  formChoice.disabled ? "cursor-not-allowed" : "cursor-pointer"
                } ${
                  consumptionTypeValue === formChoice.type
                    ? "border-primary bg-white"
                    : "border-secondary bg-light-gray text-gray"
                }`}
              >
                {formChoice.label}
                <input
                  type="radio"
                  id={formChoice.type}
                  value={formChoice.type}
                  disabled={formChoice.disabled}
                  {...register("consumptionType")}
                  checked={consumptionTypeValue === formChoice.type}
                  className="hidden"
                />
              </label>
            </div>
          ))}
        </div>

        {/* Conditional Fields */}
        {consumptionTypeValue === "average" && (
          <div className="w-full">
            <label
              htmlFor="average"
              className="block text-sm font-semibold mb-1"
            >
              Average consumption (SAR)
            </label>
            <input
              type="number"
              id="average"
              placeholder="Enter your average consumption SAR"
              className={`w-full p-3 border rounded-md ${
                errors.averageConsumption
                  ? "border-red-500"
                  : "border-secondary"
              }`}
              {...register("averageConsumption", { valueAsNumber: true })}
            />
            {errors.averageConsumption && (
              <p className="text-red-500 text-sm mt-1">
                {errors.averageConsumption.message}
              </p>
            )}
          </div>
        )}

        {consumptionTypeValue === "monthly" && (
          <div className="grid sm:grid-cols-3 grid-cols-6 gap-x-6 gap-y-4 mb-4">
            {monthlyConsumption?.map((_, index) => (
              <div key={`month-${index}`} className="w-full">
                <label
                  htmlFor={`month-${index}`}
                  className="block text-sm font-semibold mb-1"
                >
                  {new Date(0, index).toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  (SAR)
                </label>
                <input
                  type="number"
                  id={`month-${index}`}
                  className={`w-full p-3 border rounded-md ${
                    errors.monthlyConsumption?.[index]
                      ? "border-red-500"
                      : "border-secondary"
                  }`}
                  {...register(`monthlyConsumption.${index}`, {
                    valueAsNumber: true,
                  })}
                />
                {errors.monthlyConsumption?.[index] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.monthlyConsumption[index]?.message}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}

        <Stack direction={"row"} gap={1} className={`w-full justify-between`}>
          <Button
            style={{
              maxWidth: 200,
              width: "100%",
            }}
            variant="secondary"
            className="rounded-xl border-black border"
            onClick={handlePrevious}
          >
            Back
          </Button>

          <Button
            style={{
              maxWidth: 200,
              width: "100%",
            }}
            variant="primary"
            className="rounded-xl border-black border"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Submitting.." : "Submit"}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default DemoConsumptionInputStep
