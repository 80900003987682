import { Box, Heading } from "src/UI"

import useDemoStepStore from "../store/useDemoStepStore"
import DemoStepProgressPanel from "./DemoStepProgressPanel"
import { useState } from "react"

const DemoModal = () => {
  const currentStepComponent = useDemoStepStore(
    (state) => state.currentStepComponent
  )

  const currentStepIndex = useDemoStepStore((state) => state.currentStepIndex)

  return (
    <div className="container mx-auto py-5 px-5">
      <Box
        style={{
          display: "flex",
          justifySelf: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 25,
          // border: "1px solid red"
        }}
        className="lg:max-w-5xl w-full md:mt-1 mt-7 mb-10"
      >
        <DemoStepProgressPanel />

        <Heading
          className="text-center leading-6"
          mobileSize="text-xl"
          level={3}
        >
          {currentStepComponent.title}
        </Heading>

        {/* <form className="w-full flex flex-col gap-5"> */}
        {currentStepComponent.component && <currentStepComponent.component />}
        {/* </form> */}
      </Box>
    </div>
  )
}

export default DemoModal
