import axios from "axios"

const axiosInstance = axios.create({
  baseURL: "https://webapi.optimalpv.com/", // Add your base URL here
})

class APIClient<T> {
  private endpoint: string

  constructor(endpoint: string) {
    this.endpoint = endpoint
  }

  getAll = () => {
    return axiosInstance.get<T[]>(this.endpoint).then((res) => res.data)
  }

  get = (id: string) => {
    return axiosInstance
      .get<T>(`${this.endpoint}/${id}`)
      .then((res) => res.data)
  }

  post = (data: T): Promise<T> => { // Ensure Promise<T> is returned
    return axiosInstance.post<T>(this.endpoint, data).then((res) => res.data);
  }

  put = (id: string, data: T) => {
    return axiosInstance
      .put<T>(`${this.endpoint}/${id}`, data)
      .then((res) => res.data)
  }

  patch = (id: string, data: Partial<T>) => {
    return axiosInstance
      .patch<T>(`${this.endpoint}/${id}`, data)
      .then((res) => res.data)
  }

  delete = (id: string) => {
    return axiosInstance
      .delete(`${this.endpoint}/${id}`)
      .then((res) => res.data)
  }
}

export default APIClient
