import React from "react"

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string // Additional class names
  style?: React.CSSProperties // Custom CSS styles
}

const Box = ({ children, className = "", style, ...props }: BoxProps) => {
  return (
    <div className={className} style={style} {...props}>
      {children}
    </div>
  )
}

export default Box
