import { Paragraph, Box, Circle, Stack } from "src/UI";
import useDemoStepStore from "../store/useDemoStepStore";

const DemoStepProgressPanel = () => {
  const { steps, currentStepIndex } = useDemoStepStore();

  return (
    <Stack direction="row" className="w-full justify-center" gap={1}>
      {steps.map((stepTitle, index) => (
        <Box key={index} className="flex flex-col items-center gap-3">
          <Circle
            size={48}
            mobileSize={40}
            className={`text-2xl font-bold ${
              currentStepIndex === index ? "bg-primary" : "bg-light-gray"
            }`}
          >
            <p>{index + 1}</p>
          </Circle>
          <Paragraph className="text-center w-28">{stepTitle}</Paragraph>
        </Box>
      ))}
    </Stack>
  );
};

export default DemoStepProgressPanel;
