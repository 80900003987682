import { Card, Typography } from "@material-tailwind/react"
import LoadingComponent from "src/components/common/loading-component"
import { SavingsDto } from "src/types/SavingsDto" // Adjust this type if necessary

const DemoResultsTables = ({ response }: { response: SavingsDto }) => {
  const solarData = response?.data

  if (!response.data) {
    return (
      <div
        style={{ height: "40vh" }}
        className="flex items-center justify-center"
      >
        <LoadingComponent />
      </div>
    )
  }

  const renderTable = (title: string, data: any) => (
    <Card className="h-full w-full sm:overflow-x-auto rounded-none border border-blue-gray-400 my-4">
      <Typography
        variant="h6"
        color="blue-gray"
        className="p-4 text-center bg-secondary text-primary font-semibold"
      >
        {title}
      </Typography>
      <table className="w-full md:table-fixed lg:table-fixed">
        <thead>
          <tr>
            {Object.keys(data).map((key) => (
              <th key={key} className="bg-secondary-hover border-secondary border py-3 text-center">
                <Typography
                  variant="small"
                  className="font-bold leading-none text-white"
                >
                  {key}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data[Object.keys(data)[0]]).map((index) => {
            const isLastRow =
              parseInt(index) ===
              Object.keys(data[Object.keys(data)[0]]).length - 1
            const rowClasses = isLastRow ? "" : "border-b border-blue-gray-70"

            return (
              <tr key={index}>
                {Object.keys(data).map((key) => (
                  <td
                    key={`${index}-${key}`}
                    className={`border border-blue-gray-200 ${rowClasses}`}
                  >
                    <Typography
                      variant="paragraph"
                      color="blue-gray"
                      className="text-center font-bold"
                    >
                      {data[key][index]}
                    </Typography>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Card>
  )

  return (
    <div className="d-flex flex-col w-full space-y-10">
      {renderTable("System Details", solarData["System Details"])}
      {renderTable("First Year Monthly", solarData["First Year Monthly"])}
      {/* {renderTable("Annual Cash Flow", solarData["Annual Cash Flow"])} */}
    </div>
  )
}

export default DemoResultsTables
