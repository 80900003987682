import LocationMapForm from './LocationMapForm';

const DemoLocationInputStep = () => {
  return (
    <>
      <form className="w-full flex flex-col gap-5">
          <LocationMapForm/>
      </form>
    </>
  )
}

export default DemoLocationInputStep
