import React from "react"

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 // Predefined gap values as numbers
  direction?: "row" | "column" // Direction of stacking
  alignItems?: "flex-start" | "center" | "flex-end" // Align items
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around" // Justify content
  border?: boolean //For Debugging
}

const gapToRem = (gap: number) => {
  // Maps numerical gap values to rems
  return `${gap * 1}rem` // Example: 1 -> 0.25rem, 2 -> 0.5rem, etc.
}

const Stack = ({
  children,
  gap = 0, // Default to no gap if none is provided
  direction = "column",
  alignItems = "flex-start",
  justifyContent = "flex-start",
  className = "",
  border,
  style,
  ...props
}: StackProps) => {
  return (
    <div
      className={`flex ${border ? "border border-red-500" : ""} ${
        direction === "row" ? "flex-row" : "flex-col"
      } items-${alignItems} justify-${justifyContent} ${className}`}
      style={{ gap: gapToRem(gap), ...style }} // Apply rem gap in inline styles
      {...props}
    >
      {children}
    </div>
  )
}

export default Stack
