import React, { CSSProperties } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

interface ButtonProps {
  variant?: "primary" | "secondary" | "danger";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset"; // Optional type prop for button
  children: React.ReactNode;
  onClick?: () => void;
  className?: string; // Allow additional class names
  style?: CSSProperties; // Allow inline styles
}

const Button = ({
  variant = "primary",
  size = "medium",
  disabled = false,
  fullWidth = false,
  type = "button", // Default button type
  children,
  onClick,
  className = '', // Default to an empty string
  style = {}, // Default to an empty object for style prop
}: ButtonProps) => {
  const isMobile = useMediaQuery('md', 'down'); // Adjust the breakpoint as needed

  const baseClasses = "rounded transition duration-200"; // Base styles

  const variantClasses = {
    primary: "bg-primary hover:bg-primary-hover",
    secondary: "bg-secondary hover:bg-secondary-hover text-white",
    danger: "bg-red-500 text-white hover:bg-red-600",
  };

  const sizeClasses = {
    small: isMobile ? "px-3 py-1 text-sm" : "px-2 py-1 text-sm",
    medium: isMobile ? "px-4 py-2 text-sm" : "px-4 py-2 text-base", // Adjust for mobile size
    large: isMobile ? "px-5 py-3 text-lg" : "px-6 py-3 text-lg",
  };

  const disabledClasses = disabled
    ? "opacity-50 cursor-not-allowed"
    : "hover:opacity-90";

  const paddingClasses = `py-2 ${className}`; // Default vertical padding combined with any additional classes

  return (
    <button
      type={type} // Use the type prop
      className={`${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${paddingClasses} ${disabledClasses} ${fullWidth ? "w-full" : ""}`}
      style={style} // Apply inline styles
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
