import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { appRoutesObj } from './app.paths';
import DemoModal from './features/demo-modal/components/DemoModal';
import Loading from './pages/loading';
import InteractiveDemoLink from './features/demo-modal/components/InteractiveDemoLink';

// Lazy load pages
const NotFoundPage = React.lazy(() => import("./pages/404"));
const LandingPage = React.lazy(() => import("./pages/landing-page"));
const DemoPage = React.lazy(() => import("./pages/demo-page"));
const ContractorPage = React.lazy(() => import("./pages/contractor-page"));
const AboutAsPage = React.lazy(() => import("./pages/about-us-page"));
const PrivacyPolicyPage = React.lazy(
  () => import("./pages/privacy-policy-page")
);

// Wrap components with Suspense
const withSuspense = (WrappedComponent: React.ReactNode) => {
  return <Suspense fallback={<Loading />}>{WrappedComponent}</Suspense>;
};

// Define the router configuration using createBrowserRouter
const router = createBrowserRouter([
  {
    path: "*",
    element: withSuspense(<NotFoundPage />),
  },
  {
    path: appRoutesObj.getLandingPagePath(),
    element: withSuspense(<LandingPage />),
  },
  {
    path: appRoutesObj.getDemoPagePath(),
    element: withSuspense(<DemoPage />),
  },
  {
    path: appRoutesObj.getContractorPagePath(),
    element: withSuspense(<ContractorPage />),
  },
  {
    path: appRoutesObj.getAboutAsPagePath(),
    element: withSuspense(<AboutAsPage />),
  },
  {
    path: appRoutesObj.getPrivacyPolicyPath(),
    element: withSuspense(<PrivacyPolicyPage />),
  },
  {
    path: appRoutesObj.getDemoModal(),
    element: withSuspense(<DemoModal />),
  },
  {
    path: appRoutesObj.getInteractiveDemo(),
    element: withSuspense(<InteractiveDemoLink />),
  },
]);

export default router;
