import { useEffect, useState } from 'react';

const breakpoints = {
  xs: 0,       // Mobile devices
  sm: 640,     // Small devices
  md: 768,     // Medium devices
  lg: 1024,    // Large devices
  xl: 1280,    // Extra large devices
};

type Breakpoint = keyof typeof breakpoints;

const useMediaQuery = (breakpoint: Breakpoint, direction: 'up' | 'down' = 'up'): boolean => {
  const [matches, setMatches] = useState<boolean>(false);

  const getMediaQuery = () => {
    const pixelValue = breakpoints[breakpoint];
    const query = direction === 'up'
      ? `(min-width: ${pixelValue}px)`
      : `(max-width: ${pixelValue - 1}px)`;
    return window.matchMedia(query);
  };

  useEffect(() => {
    const mediaQuery = getMediaQuery();

    const handleMediaChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener('change', handleMediaChange);
    setMatches(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, [breakpoint, direction]);

  return matches;
};

export default useMediaQuery;
