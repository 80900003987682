import { create } from "zustand"
import { GoogleMapData } from "../types/LocationFormType"
import { ConsumptionFormData } from "../types/ConsumptionFormType"

interface SavingsQuery {
  location: GoogleMapData
  zoomLevel: number
  mapType: string
  consumption: ConsumptionFormData
}

interface DemoSavingsStore {
  savingsQuery: SavingsQuery
  setLocation: (location: GoogleMapData) => void
  setZoomLevel: (value: number) => void
  setMapType: (type: string) => void
  setConsumption: (consumption: ConsumptionFormData) => void
  clear: () => void // Add clear function
}

const useDemoSavingsStore = create<DemoSavingsStore>((set) => ({
  savingsQuery: {} as SavingsQuery,
  setLocation: (location) =>
    set((store) => ({ savingsQuery: { ...store.savingsQuery, location } })),
  setZoomLevel: (value) =>
    set((store) => ({
      savingsQuery: { ...store.savingsQuery, zoomLevel: value },
    })),
  setMapType: (type) =>
    set((store) => ({
      savingsQuery: { ...store.savingsQuery, mapType: type },
    })),
  setConsumption: (consumption) =>
    set((store) => ({ savingsQuery: { ...store.savingsQuery, consumption } })),

  // Clear function to reset the store to its initial state
  clear: () => {
    set({
      savingsQuery: {} as SavingsQuery,
    })
  },
}))

export default useDemoSavingsStore
